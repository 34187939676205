<template>
  <v-card dark outlined>
    <v-card-title class="pb-0">
      Select Event &amp; Year
    </v-card-title>
    <v-list>	
      <v-list-item>		
        <v-list-item-action>	
          <v-select
            :items="allYears"
            v-model="selectedYear"
            item-text="Year"
            return-object
            filled
            dense
            hide-details
            outlined
            label="Year"
          >
          </v-select>
        </v-list-item-action>	
      </v-list-item>
      <v-list-item>
        <v-list-item-action>	
          <v-autocomplete
            :items="allEventsByYear"
            v-model="selectedEvent"
            item-text="EventName"
            return-object
            filled
            dense
            outlined
            hide-details
            label="Event"
            @input="handleChangeEvent"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  <v-icon small color="red lighten-1">
                    mdi-information
                  </v-icon>
                  No event found
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-list-item-action>	
      </v-list-item>	
	
    </v-list>	
  </v-card>	
</template>

<script>
import {
  mapState,
  mapMutations,
  mapActions
} from 'vuex'
export default {
  created() {
    this.populateYears();
    // console.log(this.globalYearFilter)
  },
  data: () => ({
    "allEventsByYear": []
  }),
  computed: {
    ...mapState('filter', ['globalYearFilter', 'globalEventFilter', 'allYears',
    // 'allEvents',
    // 'allTotalEvents', 
      'filterYear',
      // 'allFetchedEvents',
      // 'globalSelectedEvent'
    ]),
    // ...mapState('admin', ['allEventsByYear']),

    selectedYear: {
      get () {
        return this.globalYearFilter
      },
      set (value) {
        // console.log('this.globalYearFilter')
        // console.log(this.globalYearFilter)
        this.setGlobalYearFilter(value)
        this.setFilterYear(value)
        this.handleEventByYear(value)
        // this.setAllEvents(value)
      }
    },
    selectedEvent: {
      get() {
        return this.globalEventFilter
      },
      set (value) {
        this.getEventMetadata(value.EventId).then(response=>{
          this.setGlobalEventFilter(response)
          })
      }
    },
  },
  watch: {
    allYears: {
      handler(value) {
        if (value && value.length > 0) {
          this.selectedYear = this.filterYear || value[0];
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations('filter', [
      'setGlobalYearFilter',
      'setGlobalEventFilter',
      'setAllStallHolders',
      'setAllVouchers',
      'setAllYears',
      'setFilterYear',
    ]),
    ...mapMutations('dashboard', ['setAllLoading']),
    ...mapActions('dashboard', ['updateDashboard']),
    ...mapActions('filter', ['extractAllStalls']),
    ...mapActions('admin', ['getRecentevent', 'getEventsByYear', 'getEventMetadata']),
    
    handleEventByYear: function (obj) {
        this.getEventsByYear(obj).then(response=>{
          this.allEventsByYear = response
        })
    },
    handleChangeEvent: function (event) {
      try {
      this.getEventMetadata(event.EventId).then(response=>{
        this.setGlobalEventFilter(response)
        if (response.Stallholders) {
          this.setAllStallHolders(response.Stallholders)
          this.extractAllStalls(response.Stallholders)
        }
        this.setAllVouchers(response.Vouchers)
        this.updateDashboard("ESC handleChangeEvent")

      })
        this.getRecentevent(event.EventId).then(response => {
          console.log("Get recent event response: ", response)
        });
      } catch (e) {
        console.log("ERROR in this.getRecentevent(event.EventId).then(response => {")
        console.log(e)
      }
    },
    populateYears() {
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth()
      if (currentMonth >= 9) {
        const years = Array.from({ length: currentYear - 2020 }, (_, index) => (2022 + index));
        this.setAllYears(years);
      } else {
        const years = Array.from({ length: currentYear - 2021 }, (_, index) => (2022 + index));
        this.setAllYears(years);
      }
    }
  },  
}
</script>

<style>

</style>